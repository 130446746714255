<template>
  <div :class="{'text-center': type!=='product', 'd-flex align-center mt-3': type==='product'}" >
    <div v-if="device==='desktop' && type==='product'" class="dot-icon p-3">
      <i class="fas fa-ellipsis-v"></i>
    </div>
    <div v-if="shortenUrl && (device==='desktop' || (device==='mobile' && type!=='product'))" :class="{'grid-show' : device==='mobile'}">
      <share-network
        v-for="network in networks"
        :network="network.network"
        :key="network.network"
        :url="sharing.url"
        :image="sharing.image"
        :title="sharing.title"
        :description="sharing.description"
        :quote="sharing.quote"
        :hashtags="sharing.hashtags"
        :twitterUser="sharing.twitterUser"
        :class="{'only-icon' : type==='product'}"
        dir="ltr"
      >
        <i :class="network.icon"></i> <span v-if="type!=='product'">{{ network.name }}</span>
      </share-network>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'share-social',
  components: {
    ShareNetwork: () => import('@/components/socialSharing/shareNetwork'),
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    shareContent: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      isTest: 'GET_MODE',
      shortenUrl: 'GET_SHARED_SHORTEN_URL',
    }),
    networks() {
      const net = [
        { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-sm fa-whatsapp' },
        { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-sm fa-facebook' },
      ];
      if (this.device === 'mobile') {
        net.push({ network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' });
      }
      if (this.isTest) {
        net.push({ network: 'skype', name: 'Skype', icon: 'fab fah fa-sm fa-skype' });
      }
      return net;
    },
    sharing() {
      return {
        url: this.shortenUrl,
        // url: this.shareUrl ? `${window.location.origin}${this.shareUrl}` : window.location.href,
        title: this.getText('title'),
        description: this.getText('description'),
        image: this.shareContent.image,
        quote: '',
        hashtags: 'flying-carpet',
      };
    },
  },
  data() {
    return {
      // networks: [
      //   { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
      //   { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-sm fa-whatsapp' },
      //   { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-sm fa-facebook' },
      //  { network: 'email', name: 'Email', icon: 'fas fa-sm fa-envelope' },
      //  { network: 'skype', name: 'Skype', icon: 'fab fah fa-lg fa-skype', color: '#00aff0' },
      // ],
    };
  },
  created() {
    this.getShortenURL();
  },
  methods: {
    getText(kind) {
      const { category, dest, hotel, airline, duration, country, rate } = this.shareContent;
      let title = '';
      if (category === 'vacation_pack') {
        title = this.$t(`share-this.vacation_pack-${kind}`, { dest, hotel, duration, rate });
      } else if (category === 'Organize_tour_packages') {
        title = this.$t(`share-this.Organize_tour_packages-${kind}`, { country, duration, rate });
      } else if (category === 'Flight_Only') {
        title = this.$t(`share-this.Flight_Only-${kind}`, { dest, airline, duration, rate });
      } else if (category === 'SportPack') {
        title = this.$t(`share-this.SportPack-${kind}`, { dest, hotel, duration, rate });
      } else {
        title = '';
      }

      return title;
    },
    getShortenURL() {
      const { link } = this.shareContent;
      const urlParams = new URLSearchParams(link);
      const dateFrom = urlParams.get('dateFrom');
      const cDate = new Date(dateFrom);
      cDate.setDate(cDate.getDate() + 1);
      this.$store.dispatch('FETCH_SHARED_SHORTEN_URL', { originUrl: encodeURIComponent(link) || '', expireDate: cDate.toISOString().slice(0, 10) || '' });
    },
  },
};
</script>

<style scoped>
a[class^="share-network-"] {
    color: #FFFFFF;
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;
    margin: 0 10px 10px 0;
    text-decoration: none;
    padding: 0.5rem;
    font-size: 1.5rem;
    /* display: inline-block; */
}
.dot-icon {
  color: black;
}
a.only-icon {
  display: -webkit-inline-box;
  margin: 0;
}
a.share-network-sms:not(.only-icon) {
  background: #333333;
}
a.share-network-facebook:not(.only-icon) {
  background: #1877f2;
}
a.share-network-email:not(.only-icon) {
  background: #333333;
}
a.share-network-whatsapp:not(.only-icon) {
  background: #25d366;
}
a.share-network-skype:not(.only-icon) {
  background: #00aff0;
}
a.share-network-facebook.only-icon {
  color: #1877f2;
}
a.share-network-email.only-icon {
  color: #333333;
}
a.share-network-whatsapp.only-icon {
  color: #25d366;
  font-size: 1.6rem;
}
a.share-network-skype.only-icon {
  color: #00aff0;
  font-size: 1.6rem;
}
a.share-network-sms.only-icon {
  color: #333333;
  font-size: 1.6rem;
}
.align-center {
  align-items: center;
}
.grid-show {
  display: grid;
}
</style>
